import { lazy, Suspense, useRef, useState } from "react";
import {
  AlertDialog,
  Banner,
  Body,
  Box,
  Card,
  CheckboxField,
  ContentRow,
  Dialog,
  Divider,
  FormErrors,
  FormSubSectionTitle,
  Heading,
  InlineLoader,
  LocalizedString,
  MobileConnectionOKIcon,
  Space,
  Stack,
  useForm,
  useIsMobileLayout,
  validators,
  WarningIcon,
} from "design-system";
import {
  boolean,
  either,
  nonEmptyArray,
  option,
  task,
  taskEither,
} from "fp-ts";
import { Option } from "fp-ts/Option";
import { LocaleKey, useFormatMessage } from "../intl";
import { UploadImageButton } from "./UploadImageButton";
import { UploadSummary } from "./UploadSummary";
import {
  constant,
  constFalse,
  constNull,
  constTrue,
  constVoid,
  flow,
  identity,
  pipe,
} from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { Task } from "fp-ts/Task";
import { ScannerIdUpload } from "./ScannerIdUpload/ScannerIdUpload";
import { useAppContext } from "../useAppContext";
import {
  CoApplicantInput,
  DocumentIdentificationFlow,
  foldTenant,
  genericError,
  UploadDocumentFlowType,
} from "../globalDomain";
import { Country, DocumentObject, DocumentToUploadDetail } from "./domain";
import * as classes from "./UploadId.treat";
import IdCardImage_CZ from "./idcard_cz.png";
import IdCardImage_SK from "./idcard_sk.png";
import Passport_CZ from "./passport_cz.png";
import Passport_SK from "./passport_sk.png";
import DrivingLicense_CZ from "./drivinglicense_cz.png";
import DrivingLicense_SK from "./drivinglicense_sk.png";
import { UploadAgainDialog } from "./UploadAgainDialog";
import { useCommand } from "../useAPI";
import * as uploadApi from "./api";
import { UploadDocumentCommand } from "./api";
import * as idUploadApi from "../IdUpload/api";
import { ClientDataOutput } from "../IdUpload/api";
import { UploadError } from "./types";
import { BiometricConsentStatus } from "../IdUpload/types";
import { BiometricConsent } from "./BiometricConsent";
import { foldFraudCheckType, FraudCheckDialog } from "./FraudCheckDialog";
import {
  cancelUploadAction,
  foldState,
  getReducerConfig,
  goToSummaryAction,
  selectRecipientAction,
  shareWithClientAction,
  startFraudCheckAction,
  startMobileFraudCheckFromSameDeviceAction,
  startMobileUploadFromSameDeviceAction,
  startUploadAction,
  startUploadModeAction,
  uploadAgainAction,
  UploadMode,
} from "./state";
import { ChooseMobileDeviceDialog } from "./ChooseMobileDeviceDialog";
import { MobileUploadStatusDialog } from "./MobileUploadStatusDialog";
import { selectedMainApplicant } from "../MortgageDashboard/mortgageDashboardUtils";
import { UploadModeDialogWithMobile } from "./UploadModeDialogWithMobile";
import { UploadModeDialog } from "./UploadModeDialog";
import { RemoteMobileDeviceDialog } from "./RemoteMobileDeviceDialog";
import {
  CheckClientDataResult,
  useCheckClientDataResult,
} from "./useCheckClientDataResult";
import { useParentSharedReducer } from "../BranchExperience/useSharedReducer";
import { ShareWithClientButton } from "../Common/ShareWithClientButton/ShareWithClientButton";
import { useBranchExperienceContext } from "../BranchExperience/BranchExperienceContext";
import { NextButton } from "../Common/NextButton";
import { useIsInPersonChannel, useIsRemoteChannel } from "../useChannel";
import { formatDocumentType } from "./utils";
import WhatToUpload from "./MobileIdUpload/UploadIdSteps/WhatToUpload";
import { usePortalStatusContext } from "../PortalStatusContext";
import { RestoreApplicationData } from "../UKontoSecondPart/api";
import { MobileRequiredDocuments } from "./MobileIdUpload/common/MobileRequiredDocuments";
import { ChooseDocumentToUpload } from "./DocumentToUpload/ChooseDocumentToUpload";
import { useValidators } from "../Common/useValidators";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

const MobileUploadFlow2 = lazy(
  () => import("./MobileIdUpload/MobileUploadFlow2")
);
const MobileUploadFlow = lazy(
  () => import("./MobileIdUpload/MobileUploadFlow")
);

const MobileCheckFlow = lazy(() => import("./MobileIdUpload/MobileCheckFlow"));

type CopyIdDocumentConsent =
  | {
      allowCopyIdDocumentAgreementEdit: boolean;
      forceHideCopyIdDocumentAgreement?: never;
    }
  | {
      allowCopyIdDocumentAgreementEdit?: never;
      forceHideCopyIdDocumentAgreement: true;
    };

type Props = {
  onContinue: (data: CheckClientDataResult) => unknown;
  onUploadAgain: TaskEither<unknown, unknown>;
  onRemove: () => unknown;
  onFailure: (
    reason: "GenericError" | "MaxAttemptsReached" | "ValidationError"
  ) => unknown;
  biometricConsent: BiometricConsentStatus;
  hideBiometricConsent?: boolean;
  setBiometricConsent: (consent: BiometricConsentStatus) => void;
  uploadCommand: UploadDocumentCommand;
  resetAfterScannerCancel: TaskEither<unknown, unknown>;
  documentIdentificationFlow: DocumentIdentificationFlow;
  extractCommand: TaskEither<unknown, ClientDataOutput>;
  forceMobileRecipientSelection?: boolean;
  productType: Option<UploadDocumentFlowType>;
  isPotentialClient?: boolean;
  restoredData?: RestoreApplicationData;
  supportForeign?: boolean;
  isUploadAgain?: boolean;
} & CoApplicantInput &
  CopyIdDocumentConsent;

type UpdateConsent =
  | {
      open: false;
    }
  | {
      open: true;
      value: Option<boolean>;
    };

const consentDialogClosed: UpdateConsent = { open: false };

export type CountryFormState = {
  country: Option<Country>;
  docType: Option<"IDCard" | "Passport">;
};

export function UploadDocuments(props: Props) {
  const {
    apiParameters: { tenant, channel },
    config: {
      biometricConsent: biometricConsentEnabled,
      useNewMobileIdUploadUx: useNewUploadId,
      externalCommunication,
    },
  } = useAppContext();

  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const { portalBlocked } = usePortalStatusContext();

  const formatMessage = useFormatMessage();
  const applicantIndex = pipe(
    props.coApplicant,
    option.fold(constant(selectedMainApplicant.index), c => c.index)
  );

  const sendBiometricConsentCommand = useCommand(
    uploadApi.sendBiometricConsent
  );
  const fraudCheckResult = useCommand(idUploadApi.fraudCheckResult);

  const sendBiometricConsent = (consent: boolean) =>
    taskEither.bracket(
      taskEither.fromIO(() => setPendingBiometricConsentCommand(true)),
      () => sendBiometricConsentCommand({ consent, applicantIndex }),
      () => taskEither.fromIO(() => setPendingBiometricConsentCommand(false))
    );

  const isMobileLayout = useIsMobileLayout();
  const useNewMobile = isMobileLayout && useNewUploadId;

  const [uploadStatus, dispatch] = useParentSharedReducer(
    getReducerConfig(channel),
    props.restoredData &&
      props.restoredData.documentsAlreadyUploaded &&
      !props.isUploadAgain
      ? {
          id: "UploadSummary",
          showUploadCompletedDialog: false,
          sharedWithClient: false,
        }
      : {
          id: useNewMobile ? "ReadyMobile" : "Ready",
        }
  );

  const [errors, setErrors] = useState<UploadError[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<Option<Country>>(
    props.restoredData &&
      props.restoredData.documentsAlreadyUploaded &&
      !props.isUploadAgain &&
      option.isSome(props.restoredData.countryDocSelection) &&
      option.isSome(props.restoredData.countryDocSelection.value.country)
      ? option.some(props.restoredData.countryDocSelection.value.country.value)
      : option.none
  );

  const [foreignDocumentType, setForeignDocumentType] = useState<
    Option<"IDCard" | "Passport">
  >(
    props.restoredData &&
      props.restoredData.documentsAlreadyUploaded &&
      !props.isUploadAgain &&
      option.isSome(props.restoredData.countryDocSelection) &&
      option.isSome(
        props.restoredData.countryDocSelection.value.foreignDocumentType
      )
      ? (option.some(
          props.restoredData.countryDocSelection.value.foreignDocumentType.value
        ) as Option<"IDCard" | "Passport">)
      : option.none
  );

  const [saveCurrentSelection, setSaveCurrentSelection] = useState(false);

  const addErrorIfMissing = (error: UploadError) => {
    if (!errors.some(e => e.id === error.id)) {
      setErrors(errors => [...errors, error]);
    }
  };

  const removeError = (error: UploadError) => {
    if (errors.some(e => e.id === error.id)) {
      setErrors(errors.filter(e => e.id !== error.id));
    }
  };

  const isRemote = useIsRemoteChannel();

  const isInPerson = useIsInPersonChannel();
  const requiresConsent =
    foldTenant(tenant, constFalse, constTrue) &&
    isInPerson &&
    !props.forceHideCopyIdDocumentAgreement;

  const [updateConsentModal, setUpdateConsentModal] = useState<UpdateConsent>(
    consentDialogClosed
  );

  const updateBiometricConsent = (consent: Option<boolean>) => {
    props.setBiometricConsent({
      consent,
      previousConsent: option.none,
    });
    removeError({ id: "MissingBiometricConsentError" });
  };

  const updateBiometricConsentMobile = (consent: Option<boolean>) => {
    pipe(
      sendBiometricConsent(option.isSome(consent) ? consent.value : false),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          props.setBiometricConsent({
            consent,
            previousConsent: option.none,
          });
          removeError({ id: "MissingBiometricConsentError" });
        })
      )
    )();
  };

  const onConsentConfirm = () => {
    if (updateConsentModal.open) {
      resetProcess();
      props.onRemove();
      updateBiometricConsent(updateConsentModal.value);
      setUpdateConsentModal(consentDialogClosed);
    }
  };

  const handleBiometricConsent = (consent: Option<boolean>) => {
    const lastConsentValue = pipe(
      props.biometricConsent.consent,
      option.getOrElse(constFalse)
    );
    const currentConsentValue = pipe(consent, option.getOrElse(constFalse));

    if (
      lastConsentValue !== currentConsentValue &&
      uploadStatus.id === "UploadSummary"
    ) {
      return setUpdateConsentModal({
        open: true,
        value: consent,
      });
    }
    updateBiometricConsent(consent);
  };

  const isBiometricConsentLocked = branchExperienceFeaturesActive
    ? false
    : pipe(props.biometricConsent.previousConsent, option.exists(identity)) ||
      uploadStatus.id === "UploadSummary";

  const [
    pendingBiometricConsentCommand,
    setPendingBiometricConsentCommand,
  ] = useState<boolean>(false);

  const checkClientDataResult = useCheckClientDataResult(
    props.extractCommand,
    props.isPotentialClient
  );

  const formatErrors = (errors: UploadError[]): Array<LocalizedString> => {
    return errors.map(e => {
      switch (e.id) {
        case "MissingBiometricConsentError":
          return formatMessage(
            "Identification.UploadDocuments.biometricData.missing"
          );
        case "GenericError":
          return formatMessage("GenericError");
        case "MissingDocumentError":
          return formatMessage(
            "Identification.UploadDocuments.missingDocumentError"
          );
        case "BiometricConsentMustBeTrueError":
          return formatMessage(
            "Identification.UploadDocuments.biometricData.mustConsent"
          );
        case "ForeignMissingCountryError":
          return formatMessage(
            "Identification.UploadDocuments.foreignCountry.missing"
          );
        case "ForeignMissingDocTypeError":
          return formatMessage(
            "Identification.UploadDocuments.foreignCountry.docType.missing"
          );
      }
    });
  };

  const cancelUpload = () => {
    dispatch(cancelUploadAction(useNewMobile));
    props.setBiometricConsent({
      consent: props.biometricConsent.consent,
      previousConsent: option.none,
    });
  };

  const resetProcess = () => {
    setErrors([]);
    setDocumentDetails(option.none);
    dispatch(cancelUploadAction(useNewMobile));
    props.setBiometricConsent({
      consent: props.biometricConsent.consent,
      previousConsent: props.biometricConsent.consent,
    });
  };

  const scrollToBiometricError = () => {
    if (!biometricSectionRef.current) {
      return;
    }
    biometricSectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const onTryUpload = () => {
    if (uploadStatus.id !== "Ready") {
      return;
    }

    pipe(
      biometricConsentEnabled && !props.hideBiometricConsent,
      boolean.fold(
        () => taskEither.rightIO(constVoid),
        () =>
          pipe(
            props.biometricConsent.consent,
            option.fold(
              () =>
                taskEither.left<UploadError>({
                  id: "MissingBiometricConsentError",
                }),
              consent =>
                pipe(
                  !consent && tenant === "SK" && isRemote,
                  boolean.fold(
                    () =>
                      pipe(
                        consent,
                        sendBiometricConsent,
                        taskEither.mapLeft(
                          (): UploadError => ({ id: "GenericError" })
                        )
                      ),
                    () =>
                      taskEither.left<UploadError>({
                        id: "BiometricConsentMustBeTrueError",
                      })
                  )
                )
            )
          )
      ),
      taskEither.bimap(
        (error: UploadError) => {
          if (error.id === "MissingBiometricConsentError") {
            scrollToBiometricError();
          }
          addErrorIfMissing(error);
          if (isInPerson && props.supportForeign === true) {
            const country = fieldPropsCountry("country").value;
            if (option.isNone(country)) {
              removeError({ id: "ForeignMissingDocTypeError" });
              addErrorIfMissing({ id: "ForeignMissingCountryError" });
            } else {
              removeError({ id: "ForeignMissingCountryError" });
              if (
                localCountryCode !== country.value.countryCode &&
                option.isNone(fieldPropsCountry("docType").value)
              ) {
                addErrorIfMissing({ id: "ForeignMissingDocTypeError" });
              } else {
                removeError({ id: "ForeignMissingDocTypeError" });
              }
            }
          }
          handleSubmitCountry();
        },
        () => {
          let hasErrors = false;
          if (isInPerson && props.supportForeign === true) {
            const country = fieldPropsCountry("country").value;
            if (option.isNone(country)) {
              removeError({ id: "ForeignMissingDocTypeError" });
              addErrorIfMissing({ id: "ForeignMissingCountryError" });
              hasErrors = true;
            } else {
              removeError({ id: "ForeignMissingCountryError" });
              if (
                localCountryCode !== country.value.countryCode &&
                option.isNone(fieldPropsCountry("docType").value)
              ) {
                addErrorIfMissing({ id: "ForeignMissingDocTypeError" });
                hasErrors = true;
              } else {
                removeError({ id: "ForeignMissingDocTypeError" });
              }
            }
          }
          handleSubmitCountry();
          if (!hasErrors) {
            dispatch(startUploadAction());
          }
        }
      )
    )();
  };

  const uploadAgain: TaskEither<unknown, unknown> = pipe(
    props.onUploadAgain,
    taskEither.chain(() => taskEither.fromIO(resetProcess))
  );

  const biometricSectionRef = useRef<HTMLElement>(null);

  const checkBiometricConsent =
    biometricConsentEnabled && !props.hideBiometricConsent
      ? taskEither.fromEither(
          pipe(
            props.biometricConsent.consent,
            option.fold(
              () =>
                either.left({
                  id: "MissingBiometricConsentError",
                } as const),
              () => either.right(null)
            )
          )
        )
      : taskEither.fromEither(either.right(null));

  const updateBiometricConsentStatus = () => {
    if (biometricConsentEnabled && !props.hideBiometricConsent) {
      props.setBiometricConsent({
        consent: props.biometricConsent.consent,
        previousConsent: props.biometricConsent.consent,
      });
    }
  };
  const { fieldProps, handleSubmit, isSubmitting } = useForm(
    {
      initialValues: {
        copyIdDocumenConsent: !!(
          props.restoredData && props.restoredData.documentsAlreadyUploaded
        ),
      },
      fieldValidators: () => ({
        copyIdDocumenConsent: requiresConsent
          ? validators.checked(
              formatMessage("Identification.client.missing.confirmation")
            )
          : undefined,
      }),
    },
    {
      onSubmit: () =>
        pipe(
          checkBiometricConsent,
          taskEither.chainW(() => {
            if (uploadStatus.id !== "UploadSummary") {
              return taskEither.left({ id: "MissingDocumentError" as const });
            }
            if (
              branchExperienceFeaturesActive &&
              !uploadStatus.sharedWithClient
            ) {
              return taskEither.rightIO(() => dispatch(shareWithClientAction));
            }
            return taskEither.fromTask<never, unknown>(extractClientData);
          }),
          taskEither.orElse(error => {
            if (error.id === "MissingBiometricConsentError")
              scrollToBiometricError();

            return taskEither.leftIO(() => {
              addErrorIfMissing(error);
              return error;
            });
          })
        ),
    }
  );

  const localCountryCode = foldTenant(
    tenant,
    () => "SVK" as NonEmptyString,
    () => "CZE" as NonEmptyString
  );

  const localCountry = foldTenant(
    tenant,
    () => {
      return { countryCode: "SVK" as NonEmptyString } as Country;
    },
    () => {
      return { countryCode: "CZE" as NonEmptyString } as Country;
    }
  );

  const { defined, definedNoExtract } = useValidators();

  const {
    fieldProps: fieldPropsCountry,
    handleSubmit: handleSubmitCountry,
    handleReset: handleResetCountry,
  } = useForm(
    {
      initialValues:
        props.restoredData &&
        option.isSome(props.restoredData.countryDocSelection)
          ? {
              country: props.restoredData.countryDocSelection.value.country,
              docType: props.restoredData.countryDocSelection.value
                .foreignDocumentType as Option<"IDCard" | "Passport">,
            }
          : {
              country: option.some(localCountry) as Option<Country>,
              docType: option.none as Option<"IDCard" | "Passport">,
            },
      fieldValidators: values => ({
        country: defined(),
        docType: pipe(
          values.country,
          option.fold(
            () => definedNoExtract<"IDCard" | "Passport">(),
            country =>
              country.countryCode === localCountryCode
                ? undefined
                : definedNoExtract<"IDCard" | "Passport">()
          )
        ),
      }),
    },
    {
      onSubmit: ({ country, docType }) =>
        taskEither.fromIO(() =>
          setDocumentDetails(
            option.some({
              country: country,
              type: docType,
            })
          )
        ),
    }
  );

  const extractClientData: Task<unknown> = pipe(
    checkClientDataResult,
    taskEither.fold(
      response => {
        switch (response.id) {
          case "Abort":
            return task.fromIO(() => props.onFailure("GenericError"));
          case "ValidationError":
            return task.fromIO(() => props.onFailure("ValidationError"));
          case "GenericError":
            return task.fromIO(() => addErrorIfMissing(genericError));
          case "UploadAgain":
            return task.fromIO(() =>
              dispatch(uploadAgainAction(response.documentMismatch))
            );
          case "FraudCheck":
            return task.fromIO(() => {
              if (props.documentIdentificationFlow === "PrimaryAndSecondary") {
                updateBiometricConsentStatus();
                props.onContinue(response.data);
              } else {
                pipe(
                  response.data.fraudCheck,
                  option.map(fraudCheck =>
                    dispatch(
                      startFraudCheckAction(
                        fraudCheck,
                        response.data.canUploadAgain
                      )
                    )
                  )
                );
              }
            });
        }
      },
      data =>
        task.fromIO(() => {
          updateBiometricConsentStatus();
          props.onContinue(data);
        })
    )
  );

  const Placeholder = (
    <ContentRow type="1-1">
      {pendingBiometricConsentCommand ? (
        <Box grow column>
          <Card grow>
            <Box
              column
              grow
              style={{ minHeight: 64 }}
              vAlignContent="center"
              hAlignContent="center"
            >
              <InlineLoader
                size="small"
                message={formatMessage("LoadingEllipsis")}
              />
            </Box>
          </Card>
        </Box>
      ) : (
        <UploadImageButton
          image={option.none}
          onClick={onTryUpload}
          label={formatMessage("Identification.UploadDocuments.idDocument")}
          disabled={portalBlocked}
        />
      )}
      <Space fluid />
    </ContentRow>
  );

  const onUploadModalConfirm = ({ mode }: { mode: UploadMode }) => {
    dispatch(startUploadModeAction(mode));
  };

  const DocumentCard = ({
    src,
    message,
  }: {
    src: string;
    message: LocaleKey;
  }) => {
    const isMobileLayout = useIsMobileLayout();

    return (
      <Box column hAlignContent="left">
        <Box className={classes.imageWrapper} hAlignContent="center">
          <img
            src={src}
            alt={formatMessage(message)}
            height={isMobileLayout ? 80 : 100}
          />
        </Box>
        <Space units={2} />
        <Body size="xx-small" weight="regular">
          {formatMessage(message)}
        </Body>
      </Box>
    );
  };

  const isPersonalProfile = pipe(
    props.productType,
    option.exists(value => value === "PersonalProfile")
  );

  const primaryDocumentsImages: DocumentObject[] = foldTenant(
    tenant,
    () =>
      isRemote && !isPersonalProfile
        ? [{ src: IdCardImage_SK, type: "IDCard" }]
        : [
            { src: IdCardImage_SK, type: "IDCard" },
            { src: Passport_CZ, type: "Passport" },
          ],
    () =>
      (isRemote || channel === "TLS_Remote") && !isPersonalProfile
        ? [{ src: IdCardImage_CZ, type: "IDCard" }]
        : [
            { src: IdCardImage_CZ, type: "IDCard" },
            { src: Passport_SK, type: "Passport" },
          ]
  );

  const secondaryDocumentsImages: DocumentObject[] = foldTenant(
    tenant,
    () =>
      !isPersonalProfile
        ? []
        : [
            { src: Passport_SK, type: "Passport" },
            { src: DrivingLicense_SK, type: "DrivingLicenseSK" },
            { src: DrivingLicense_CZ, type: "DrivingLicenseCZ" },
          ],
    () =>
      isPersonalProfile
        ? [
            { src: Passport_CZ, type: "Passport" },
            { src: DrivingLicense_CZ, type: "DrivingLicenseCZ" },
            { src: DrivingLicense_SK, type: "DrivingLicenseSK" },
          ]
        : isRemote || channel === "TLS_Remote"
        ? [
            { src: Passport_CZ, type: "Passport" },
            { src: DrivingLicense_CZ, type: "DrivingLicenseCZ" },
          ]
        : []
  );

  const renderDocumentImage = (doc: DocumentObject) => {
    return (
      <DocumentCard src={doc.src} message={formatDocumentType(doc.type)} />
    );
  };

  const hasPrimaryDocuments =
    props.documentIdentificationFlow === "Primary" ||
    props.documentIdentificationFlow === "PrimaryAndSecondary" ||
    props.documentIdentificationFlow === "PrimaryAndLivenessCheck";

  const hasSecondaryDocuments =
    props.documentIdentificationFlow === "Secondary" ||
    props.documentIdentificationFlow === "PrimaryAndSecondary";

  const renderIdentificationDocuments = (
    documentTitle: LocalizedString,
    documentsArray: DocumentObject[]
  ) => (
    <Box column>
      <FormSubSectionTitle title={documentTitle} />
      <Space units={6} />
      <Stack units={6} grow shrink column={isMobileLayout}>
        {documentsArray.map(renderDocumentImage)}
      </Stack>
    </Box>
  );

  const [documentDetails, setDocumentDetails] = useState<
    Option<DocumentToUploadDetail>
  >(option.none);

  const showUploadStatus = foldState(uploadStatus, {
    whenReady: () => Placeholder,
    whenReadyMobile: () => (
      <WhatToUpload
        hasPrimaryDocuments={hasPrimaryDocuments}
        hasSecondaryDocuments={hasSecondaryDocuments}
        documentIdentificationFlow={props.documentIdentificationFlow}
        productType={props.productType}
        biometricConsent={props.biometricConsent.consent}
        mustGiveConsent={tenant === "SK" && isRemote}
        onBiometricConsent={(given, mockScanner) => {
          updateBiometricConsentMobile(option.some(given));
          dispatch(
            mockScanner
              ? startUploadModeAction("_MockScanner")
              : startMobileUploadFromSameDeviceAction
          );
        }}
      />
    ),
    whenChooseUploadMode: () => (
      <>
        {Placeholder}
        {externalCommunication ? (
          <UploadModeDialogWithMobile
            onConfirm={onUploadModalConfirm}
            onDismiss={cancelUpload}
            coApplicant={props.coApplicant}
            type="DocumentUpload"
          />
        ) : (
          <UploadModeDialog
            onConfirm={onUploadModalConfirm}
            onDismiss={cancelUpload}
            mobileFeatureStatus="hidden"
          />
        )}
      </>
    ),
    whenScannerUpload: ({ mocked }) => (
      <ScannerIdUpload
        _mockScanner={mocked}
        onDismiss={flow(props.resetAfterScannerCancel, cancelUpload)}
        onContinue={() => {
          setErrors([]);
          dispatch(goToSummaryAction(false));
        }}
        coApplicant={props.coApplicant}
        documentIdentificationFlow={props.documentIdentificationFlow}
        uploadCommand={props.uploadCommand}
        documentDetails={documentDetails}
      />
    ),
    whenChooseMobileRecipient: status =>
      channel === "3P_InPerson" && !props.forceMobileRecipientSelection ? (
        <RemoteMobileDeviceDialog
          onDismiss={cancelUpload}
          onSelectRecipient={flow(selectRecipientAction, dispatch)}
          onUploadFromSameDevice={() =>
            dispatch(startMobileUploadFromSameDeviceAction)
          }
          onMockScannerUpload={option.some(() =>
            dispatch(startUploadModeAction("_MockScanner"))
          )}
          mobileRecipientType="Banker"
          type={status.flowType}
          coApplicant={props.coApplicant}
        />
      ) : (
        <ChooseMobileDeviceDialog
          type={status.flowType}
          onSelect={flow(selectRecipientAction, dispatch)}
          onDismiss={cancelUpload}
          onUploadAgain={pipe(
            uploadAgain,
            option.fromPredicate(
              () =>
                status.canUploadAgain && status.flowType !== "DocumentUpload"
            )
          )}
          onMockScannerUpload={
            channel === "3P_InPerson" && props.forceMobileRecipientSelection
              ? option.some(() =>
                  dispatch(startUploadModeAction("_MockScanner"))
                )
              : option.none
          }
          coApplicant={props.coApplicant}
        />
      ),
    whenRemoteMobileRecipient: status => (
      <RemoteMobileDeviceDialog
        onDismiss={cancelUpload}
        onSelectRecipient={flow(selectRecipientAction, dispatch)}
        onUploadFromSameDevice={() =>
          status.flowType === "DocumentUpload"
            ? dispatch(startMobileUploadFromSameDeviceAction)
            : dispatch(startMobileFraudCheckFromSameDeviceAction)
        }
        onMockScannerUpload={option.some(() =>
          dispatch(startUploadModeAction("_MockScanner"))
        )}
        mobileRecipientType="Client"
        type={status.flowType}
        coApplicant={props.coApplicant}
      />
    ),
    whenMobileUpload: status => (
      <>
        {Placeholder}
        <MobileUploadStatusDialog
          flowType="DocumentUpload"
          documentIdentificationFlow={props.documentIdentificationFlow}
          recipient={status.recipient}
          onDismiss={cancelUpload}
          onMaxAttemptsReached={() => props.onFailure("MaxAttemptsReached")}
          onUploadCompleted={task.fromIO(() => {
            setErrors([]);
            dispatch(goToSummaryAction(true));
          })}
          coApplicant={props.coApplicant}
          documentToUploadDetails={documentDetails}
        />
      </>
    ),
    whenMobileUploadSameDevice: () => (
      <Suspense fallback={constNull}>
        {useNewMobile ? (
          <MobileUploadFlow2
            documentIdentificationFlow={props.documentIdentificationFlow}
            isSameDevice
            onComplete={option.some(() => {
              setErrors([]);
              dispatch(goToSummaryAction(false));
            })}
            coApplicant={props.coApplicant}
            uploadDocumentFlowType={props.productType}
            documentCodes={option.none}
            countryCode={option.none}
            documentType={option.none}
          />
        ) : (
          <MobileUploadFlow
            documentIdentificationFlow={props.documentIdentificationFlow}
            isSameDevice
            onComplete={option.some(() => {
              setErrors([]);
              dispatch(goToSummaryAction(false));
            })}
            coApplicant={props.coApplicant}
            uploadDocumentFlowType={props.productType}
          />
        )}
      </Suspense>
    ),
    whenMobileFraudCheckSameDevice: status => (
      <Suspense fallback={constNull}>
        <MobileCheckFlow
          type="SelfieFraudCheck"
          documentIdentificationFlow={props.documentIdentificationFlow}
          isSameDevice
          onComplete={option.some(() =>
            pipe(
              fraudCheckResult({
                zenIdPurpose: foldFraudCheckType(status.check, {
                  SelfieFraudCheck: constant("FRAUD_CHECK_SELFIE"),
                  HologramFraudCheck: constant("FRAUD_CHECK_HOLOGRAM"),
                  HologramAndSelfieFraudCheck: constant(
                    "FRAUD_CHECK_SELFIE_AND_HOLOGRAM"
                  ),
                }),
              }),
              taskEither.fold(
                () => task.fromIO(() => addErrorIfMissing(genericError)),
                () =>
                  task.fromIO(() => {
                    setErrors([]);
                    dispatch(goToSummaryAction(false));
                  })
              )
            )()
          )}
          coApplicant={props.coApplicant}
          uploadDocumentFlowType={props.productType}
        />
      </Suspense>
    ),
    whenUploadSummary: status => (
      <>
        <UploadSummary
          documentIdentificationFlow={props.documentIdentificationFlow}
          coApplicant={props.coApplicant}
          onRemove={option.some(flow(props.onRemove, resetProcess))}
          country={selectedCountry}
          foreignDocumentType={foreignDocumentType}
          saveCurrentSelection={saveCurrentSelection}
        />
        {status.showUploadCompletedDialog && (
          <Dialog
            variant="center"
            size="medium"
            onDismiss={option.some(() => dispatch(goToSummaryAction(false)))}
            title={formatMessage(
              "Identification.UploadDocuments.successModalTitle"
            )}
            subtitle={formatMessage(
              "Identification.UploadDocuments.successModalDescription"
            )}
            icon={MobileConnectionOKIcon}
            actions={[
              {
                variant: "primary",
                action: () => dispatch(goToSummaryAction(false)),
                label: formatMessage("Next"),
              },
            ]}
          />
        )}
      </>
    ),
    whenUploadAgain: status => (
      <UploadAgainDialog
        onDismiss={uploadAgain}
        documentMismatch={status.documentMismatch}
      />
    ),
    whenFraudCheck: status => (
      <FraudCheckDialog
        fraudCheck={status.check}
        documentIdentificationFlow={props.documentIdentificationFlow}
        canUploadAgain={status.canUploadAgain}
        recipient={status.recipient}
        coApplicant={props.coApplicant}
        onDismiss={() => dispatch(goToSummaryAction(false))}
        onError={reason => {
          switch (reason) {
            case "GenericError":
              return addErrorIfMissing(genericError);
            case "MaxAttemptsReached":
              return props.onFailure("MaxAttemptsReached");
          }
        }}
        onAbort={() => props.onFailure("GenericError")}
        onUploadAgain={uploadAgain}
        onFraudCheck={flow(startFraudCheckAction, dispatch)}
        onContinue={pipe(
          task.fromIO(() => dispatch(goToSummaryAction(false))),
          task.chain(() => extractClientData)
        )}
      />
    ),
  });

  const alertDialog = updateConsentModal.open && (
    <AlertDialog
      icon={WarningIcon}
      type="confirmation"
      onConfirm={onConsentConfirm}
      onCancel={() => setUpdateConsentModal(consentDialogClosed)}
      onDismiss={() => setUpdateConsentModal(consentDialogClosed)}
      confirmLabel={formatMessage(
        "Identification.UploadDocuments.biometricData.changeDialog.continue"
      )}
      cancelLabel={formatMessage("Cancel")}
      message={formatMessage(
        "Identification.UploadDocuments.biometricData.changeDialog.message"
      )}
      title={formatMessage(
        "Identification.UploadDocuments.biometricData.changeDialog.title"
      )}
    />
  );

  const biometricSection = (
    <>
      <BiometricConsent
        ref={biometricSectionRef}
        haveErrors={errors.some(e => e.id === "MissingBiometricConsentError")}
        biometricConsent={props.biometricConsent.consent}
        onBiometricConsent={handleBiometricConsent}
        isRadioDisabled={isBiometricConsentLocked || portalBlocked}
        mustGiveConsent={tenant === "SK" && isRemote}
      />
      {alertDialog}
      <Space units={6} />
      <Divider />
    </>
  );

  const desktopRequiredDocuments = () => (
    <>
      {hasPrimaryDocuments &&
        renderIdentificationDocuments(
          formatMessage(
            "Identification.UploadDocuments.step1.validPrimaryDocuments"
          ),
          primaryDocumentsImages
        )}
      {hasSecondaryDocuments &&
        renderIdentificationDocuments(
          formatMessage(
            "Identification.UploadDocuments.step1.validSecondaryDocuments"
          ),
          secondaryDocumentsImages
        )}
    </>
  );

  return (uploadStatus.id === "MobileUploadSameDevice" ||
    uploadStatus.id === "ReadyMobile" ||
    uploadStatus.id === "MobileFraudCheckSameDevice") &&
    isMobileLayout ? (
    showUploadStatus
  ) : (
    <Stack grow column units={6}>
      {biometricConsentEnabled &&
        !props.hideBiometricConsent &&
        biometricSection}
      <Heading size={isMobileLayout ? "x-small" : "small"} weight="medium">
        {formatMessage("Identification.UploadDocuments.title")}
      </Heading>
      {isInPerson && props.supportForeign === true && (
        <ChooseDocumentToUpload
          localCountryCode={localCountryCode}
          fieldProps={fieldPropsCountry}
          handleReset={() => {
            handleResetCountry();
            removeError({ id: "ForeignMissingCountryError" });
            removeError({ id: "ForeignMissingDocTypeError" });
          }}
          onNoCountries={() => {
            setDocumentDetails(option.none);
            dispatch(startUploadAction());
          }}
          onCountryChange={country => {
            setSaveCurrentSelection(true);
            setSelectedCountry(country);
          }}
          onDocTypeChange={docType => {
            setSaveCurrentSelection(true);
            setForeignDocumentType(docType);
          }}
          restoredData={props.restoredData}
          disabled={uploadStatus.id === "UploadSummary"}
        />
      )}
      {useNewMobile ? (
        <MobileRequiredDocuments
          hasPrimaryDocuments={hasPrimaryDocuments}
          hasSecondaryDocuments={hasSecondaryDocuments}
          documentIdentificationFlow={props.documentIdentificationFlow}
          productType={props.productType}
        />
      ) : (
        desktopRequiredDocuments()
      )}
      {showUploadStatus}
      {requiresConsent && (
        <CheckboxField
          {...fieldProps("copyIdDocumenConsent")}
          label={formatMessage("Identification.client.agreement")}
          disabled={
            (!props.allowCopyIdDocumentAgreementEdit &&
              fieldProps("copyIdDocumenConsent").value) ||
            portalBlocked ||
            isSubmitting ||
            (uploadStatus.id === "UploadSummary" &&
              uploadStatus.sharedWithClient)
          }
        />
      )}
      <FormErrors
        errors={pipe(errors, formatErrors, nonEmptyArray.fromArray)}
      />
      <Space units={5} />
      {/* The following fragment is a temporary workaround for: https://buildo.kaiten.io/space/29286/card/2311626 */}
      <>
        {uploadStatus.id === "UploadSummary" &&
          uploadStatus.sharedWithClient && (
            <Banner
              type="informative"
              title={option.none}
              actions={option.none}
              onDismiss={option.none}
              content={formatMessage("BranchExperience.waitingConfirmation")}
            />
          )}
      </>
      <Space units={2} />
      {isMobileLayout ? (
        <NextButton
          action={handleSubmit}
          data-test-id="upload-documents-next-mobile"
        />
      ) : (
        <Box hAlignContent="right">
          <ShareWithClientButton
            submitLabel={formatMessage("Next")}
            branchExperienceState={
              uploadStatus.id === "UploadSummary" &&
              uploadStatus.sharedWithClient
                ? "sharedWithClient"
                : "notShared"
            }
            action={handleSubmit}
            data-test-id="upload-documents-next"
            disabled={portalBlocked}
          />
        </Box>
      )}
    </Stack>
  );
}
